<template>
  <div>
    <header id="header" class="fixed-top border-bottom bg-main shadow-sm">
      <div class="container-fluid shadow-sm">
        <nav class="navbar navbar-expand-md navbar-dark px-0 pr-sm-3">
          <a
            @click="back"
            class="btn p-0 mr-md-4 text-light h5 mb-0"
          >
            <i class="fas fa-arrow-left"></i>
          </a>
          <span
            class="
              navbar-brand
              bg-transparent
              rounded
              p-0
              text-center
              mr-0 mr-md-5
            "
          >
            <span class="h6 font-weight-bold text-truncate">Produk Grup</span>
          </span>
          <div class="text-nowrap order-md-last">
            <a id="shareBtn" class="btn px-0 d-inline-block mr-3" @click="shareData">
              <i class="fa fa-share-alt text-light"></i>
            </a>
            <router-link
              :to="'/jual/produk-group/edit/' + state.id"
              class="btn px-0 d-inline-block"
            >
              <i class="fas fa-pencil-alt text-light"></i>
            </router-link>
          </div>
        </nav>
      </div>
      <!-- .container -->
    </header>

    <div class="container-fluid mt-header">
      <div class="row" id="body-sidemenu">
        <!-- MAIN -->
        <div id="main-content" class="col pb-5 with-fixed-sidebar">
          <div
            class="jumbotron jumbotron-fluid mb-0 shadow-sm no-side-margin"
            :style="
              'background-image: url(' + isPhoto(state.data.photo)+ ');'
            "
          >
            <div class="container text-center">
              <p class="h5 my-2 text-white">{{ state.data.name }}</p>
            </div>
          </div>
          <form @submit.prevent="searchData">
            <div class="input-group input-group-sm my-4">
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Cari..."
                  v-model="state.search"
                />
                <span class="input-group-append">
                  <button type="submit" class="btn btn-outline-main">
                    <i class="fa fa-search"></i>
                  </button>
                </span>
              </div>
            </div>
          </form>

          <div class="small text-truncate">
            Daftar Produk untuk {{ state.data.name }}:
          </div>

          <div id="resultList" class="row mt-3 mb-5">
            <div
              class="col-12 col-md-6 col-lg-4 col-xl-3"
              v-for="my in state.myProduk"
              :key="my.id"
            >
              <div class="card mb-2">
                <div class="card-body p-0">
                  <div class="float-left w-25 border-right">
                      <div
                        @click="goInfo(my.id)"
                        class="cropped"
                        :style="
                          'background-image: url(' +
                          state.url +
                          my.produk.photo +
                          ');'
                        "
                      >
                      </div>
                  </div>
                  <div class="float-right w-75 pt-2 pl-3">
                    <div class="card-title mb-1">
                      <!-- <a class="btn float-right" @click="deleteRelasi(my.id)">
                        <i
                          class="fas fa-trash text-main"
                          style="font-size: 20px"
                        ></i>
                      </a> -->
                      <a
                        @click="goInfo(my.id)"
                        class="
                          text-main
                          font-weight-bold
                          mb-1
                          text-truncate
                        "
                      >
                        {{ my.produk.name }}
                      </a>
                    </div>
                    <p class="card-text mb-1 text-truncate">
                      <span class="font-weight-bold currency"
                        >Rp.
                        {{
                          changeMoney(
                            Number(my.margin) + Number(my.produk.price)
                          )
                        }}</span
                      >
                    </p>
                    <p class="card-text mb-0 text-truncate small">
                      <span class="text-muted"
                        >Harga: Rp. {{ changeMoney(my.produk.price) }}</span
                      >
                      <span class="text-muted"
                        >, Margin: Rp. {{ changeMoney(my.margin) }}</span
                      >
                    </p>
                    <!-- <button type="button" class="btn btn-sm btn-update" @click="changeIndex(my)">Update Margin</button>
                    <div class="input-group input-group-sm my-1" v-show="state.indexRelasi == my.id">
                      <span class="small pt-2 mr-2">Margin: </span>
                      <currency-input
                        class="form-control currency"
                        v-model="state.marginRelasi"
                        :options="{ currency: 'IDR' }"
                        name="price"
                      />
                      <div class="input-group-append">
                        <a
                          @click="updateRelasi(my)"
                          class="saveProduct input-group-text bg-transparent mr-2"
                          >Update</a
                        >
                      </div>
                    </div> -->
                  </div>

                  <div class="clearfix"></div>
                  
                </div>
              </div>
            </div>
            <!-- .col -->
          </div>
          <!-- .row -->
          <a
            href="javascript:void(0);"
            id="btn-float"
            data-toggle="modal"
            data-target="#produkModal"
            class="
              bg-warning
              position-fixed
              rounded-circle
              shadow
              text-dark text-center
            "
          >
            <div class="d-flex justify-content-center mt-3">
              <i class="fas fa-plus" style="font-size: 25px"></i>
            </div>
          </a>
        </div>
        <!-- main-content -->
      </div>
      <!-- .row -->
    </div>
    <!-- .container -->

    <div class="modal fade" id="produkModal">
      <div class="modal-dialog modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <h6 class="modal-title">Pilih Produk</h6>
            <button type="button" class="close" data-dismiss="modal">
              &times;
            </button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="modalSearchData">
              <div class="input-group input-group-sm">
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Cari..."
                    v-model="state.modalSearch"
                  />
                  <span class="input-group-append">
                    <button type="submit" class="btn btn-outline-main">
                      <i class="fa fa-search"></i>
                    </button>
                  </span>
                </div>
              </div>
            </form>
            <label>Jenis Produk:</label>
            <select class="form-control form-control-sm" id="selProd" required>
              <option
                v-for="cat in state.category"
                :key="cat.id"
                :value="cat.id"
                @click="modalSelectStatus(cat.id)"
              >
                {{ cat.name }}
              </option>
            </select>
            <hr class="no-side-margin" />
            <ul class="list-group" id="productList">
              <li
                class="list-group-item text-truncate"
                v-for="pr in state.produks"
                :key="pr.id"
                @click="selectIndex(pr.id)"
              >
                <span class="nama-produk text-main mb-0">{{ pr.name }} </span>
                <small class="text-muted mb-0"
                  ><span class="grup">( {{ pr.category.name }}</span
                  >,
                  <span class="currency"
                    >Rp. {{ changeMoney(pr.price) }})</span
                  ></small
                >
                <div
                  class="input-group input-group-sm my-0"
                  v-show="pr.id == state.indexProduk"
                >
                  <span class="small pt-2 mr-2">Margin: </span>
                  <currency-input
                    class="form-control currency"
                    v-model="state.form.margin"
                    :options="{ currency: 'IDR' }"
                    name="price"
                  />
                  <div class="input-group-append">
                    <a
                      class="saveProduct input-group-text bg-transparent"
                      @click="addProduk(pr)"
                      ><i class="fas fa-plus"></i> Tambah</a
                    >
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- #produkModal -->
  </div>
</template>
<script>
import { reactive, onMounted } from "vue";
import axios from "axios";
import { useRoute, useRouter } from "vue-router";
import CurrencyInput from "../../../../helpers/CurrencyInput.vue";
import { useVueSweetAlert2 } from "../../../../useVueSweetAlert2.js";
import $ from "jquery";
import Share from '../../../../components/social_media/Share.js';
import imageDefault from '../../../../assets/img/img.png';

export default {
  setup() {
    const route = useRoute();
    const router = useRouter();
    const swal = useVueSweetAlert2();
    const state = reactive({
      id: "",
      data: {},
      exist: false,
      search: "",
      url: process.env.VUE_APP_API_URL,
      produks: {},
      myProduk: {},
      category: {},
      indexProduk: "",
      modalSearch: "",
      form: {
        margin: 0,
        group_id: "",
        produk_id: "",
      },
      indexRelasi: '',
      marginRelasi: 0,
    });
    const loadData = async () => {
      await axios.get("/api/seller/produk-group/" + state.id).then((res) => {
        if (res.data.success) {
          state.data = res.data.data;
        }
      });
    };
    //get produk on group
    const getProdukRelasi = () => {
      axios
        .get("/api/seller/produk-group/getProduk/" + state.id)
        .then((res) => {
          if (res.data.success) {
            state.myProduk = res.data.data;
          }
        });
    };
    //get all produk (produk on modal)
    const getAllProduk = () => {
      axios.get("/api/seller/produk-group/getAllProduk").then((res) => {
        if (res.data.success) {
          state.produks = res.data.data;
        }
      });
    };
    //get all category
    const getCategory = () => {
      axios.get("/api/seller/produk-group/getCategory").then((res) => {
        if (res.data.success) {
          state.category = res.data.data;
        }
      });
    };
    onMounted(() => {
      state.id = route.params.id;
      loadData();
      getProdukRelasi();
      getAllProduk();
      getCategory();
    });
    //search on detail
    const searchData = () => {
      if (state.search) {
        axios
          .get(
            "/api/seller/produk-group/getProduk/" +
              state.id +
              "?search=" +
              state.search
          )
          .then((res) => {
            if (res.data.success) {
              state.myProduk = res.data.data;
            }
          });
      }
    };
    //search on modal
    const modalSearchData = () => {
      if (state.modalSearch) {
        axios
          .get(
            "/api/seller/produk-group/getAllProduk?search=" + state.modalSearch
          )
          .then((res) => {
            if (res.data.success) {
              state.produks = res.data.data;
            }
          });
      }
    };
    const modalSelectStatus = (dt) => {
      axios
        .get("/api/seller/produk-group/getAllProduk?category_id=" + dt)
        .then((res) => {
          if (res.data.success) {
            state.produks = res.data.data;
          }
        });
    };
    const changeMoney = (dt) => {
      let cur = Math.ceil(Number(dt));
      let mo = cur.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
      let ret = mo.split(".")[0];
      return ret;
    };
    //set index
    const selectIndex = (dt) => {
      state.indexProduk = dt;
    };
    //add produk from modal
    const addProduk = (produk) => {
      if (state.form.margin >= 0) {
        state.form.group_id = state.id;
        state.form.produk_id = produk.id;
        axios
          .post("/api/seller/produk-group/addProduk", state.form)
          .then((res) => {
            if (res.data.success) {
              swal.fire({
                icon: "success",
                title: "Selamat",
                text: "Produk Berhasil Ditambahkan!",
              });
            }
            $("#produkModal").modal("toggle");
            getProdukRelasi();
            state.form.margin = 0;
          });
      } else {
        swal.fire("", "Margin Minimal Rp.0");
      }
    };
    const goInfo = (relasi_id) => {
      router.push({path: '/jual/produk-group/info/'+relasi_id});
    }
    const updateRelasi = (relasi) => {
      //update margin
      if (state.marginRelasi > 0) {
        relasi.margin = state.marginRelasi;
        axios
          .post("/api/seller/produk-group/addProduk", relasi)
          .then((res) => {
            if (res.data.success) {
              swal.fire({
                icon: "success",
                title: "Selamat",
                text: "Margin Berhasil DiRubah!",
              });
            }
            getProdukRelasi();
            state.form.margin  = 0;
            state.marginRelasi = 0;
            state.indexRelasi = '';
        });
      }else{
        swal.fire("", "Margin Harus lebih dari 0");
      }
    }
    const deleteRelasi = (produk_id) => {
      swal
        .fire({
          text: "Apakah Anda yakin Akan Menghapus Produk Ini?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Ya",
          cancelButtonText: "Tidak",
          confirmButtonColor: "#b50b1b",
        })
        .then((result) => {
          if (result.isConfirmed) {
            axios
              .delete("/api/seller/produk-group/relasiProduk/" + produk_id)
              .then((res) => {
                if (res.data.success) {
                  swal.fire({
                    icon: "success",
                    title: "Info",
                    text: "Produk Berhasil Di Hapus!",
                  });
                  getProdukRelasi();
                }
              });
          }
        });
    }
    const changeIndex = (my) => {
      // state.marginRelasi = Number(my.margin);
      state.indexRelasi = my.id;
    }
    const shareData = async () => {
      //share
      const url = process.env.VUE_APP_HOME_URL + '/home/pgroup/'+state.data.key;
      let dt = {
        title: state.data.name,
        description: "",
        url: url,
      }
      Share.run('whatsapp', dt);
      
    }
    const isPhoto = (dt) => {
        if(dt){
            return state.url + dt;
        }else {
            return imageDefault;
        }
    }
    const back = () => {
      window.history.back();
    }
    return {
      state,
      searchData,
      changeMoney,
      modalSearchData,
      modalSelectStatus,
      selectIndex,
      addProduk,
      goInfo,
      updateRelasi,
      deleteRelasi,
      changeIndex,
      shareData,
      isPhoto,
      back
    };
  },
  components: {
    CurrencyInput,
  },
};
</script>
<style scoped>
.btn-update{
  font-size: 10px;
  background-color: #c09;
  color: white;
  margin: 10px 0px;
}
</style>